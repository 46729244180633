<template>
    <div>
		<el-table border v-loading="loading" :data="dataList">
			<el-table-column label="渠道编码" prop="channelNo" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="渠道名称" prop="name" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="渠道代码" prop="code" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="一级代理商" prop="oneAgentNo" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="是否可用" prop="available" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="scope.row.available==1">可用</span>
					<span v-if="scope.row.available==0">不可用</span>
				</template>
			</el-table-column>
			<el-table-column label="是否验证签名" prop="ignoreSign" min-width="140" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="scope.row.ignoreSign==0">是</span>
					<span v-if="scope.row.ignoreSign==1">否</span>
				</template>
			</el-table-column>
			<el-table-column label="分润计算方式" prop="profitAllocationType" min-width="140" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span>{{dicts.PROFIT_ALLOCATION[scope.row.profitAllocationType]}}</span>
				</template>
			</el-table-column>
			<el-table-column label="创建时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="修改时间" prop="updateTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="220" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleDetail(scope.row)">详情</el-button>
					<el-button size="mini" type="text" @click="handleUpdate(scope.row)" v-permission="'BASIC_CONFIG_UPDATE'">修改</el-button>
					<el-button size="mini" type="text" @click="handleEnable(scope.row)" v-permission="'BASIC_CONFIG_UPDATE'">
						{{scope.row.available==1?'禁用':'启用'}}
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

		<CustomForm :dicts="dicts"
					:on-fresh="showCustomForm"
					:isAdd="isAdd"
					:isLook="isLook"
					:selectRow="selectRow"
					@on-close="onClose" ></CustomForm>
	</div>
</template>

<script>
	import {BasicApi} from '@/api';
	import CustomForm from "./CustomForm";
	export default {
		name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false,
				isLook: true
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await BasicApi.acqChannel.pageList(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleEnable(row) {
				let available = row.available === '1'?'0':'1'
				let text = row.available === '1' ? "FROM.DISABLE" : "FROM.ENABLE";
				this.$confirm(this.translate('FROM.CONFIRM_OPERATION', {module: 'MODULE.CONFIG', operation: text}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await BasicApi.acqChannel.changeAvailable(row.channelNo,available);
					if (result.success) {
						this.getList();
					}
				}).catch(() => this.getList());
			},
			async handleUpdate(row) {
				let result = await BasicApi.acqChannel.queryByChannelNo(row.channelNo);
				if (result.success) {
					this.showCustomForm = true;
					this.selectRow = result.data;
					this.isAdd = false;
					this.isLook = true
				}
			},
			async handleDetail(row) {
				let result = await BasicApi.acqChannel.queryByChannelNo(row.channelNo);
				if (result.success) {
					this.showCustomForm = true;
					this.selectRow = result.data;
					this.isAdd = false;
					this.isLook = false
				}
			},
			onClose(){
				this.showCustomForm = false;
				this.getList();
			}
		}
	};
</script>

<style scoped>

</style>
